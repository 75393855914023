import { Stack, Typography } from "@mui/material";
import { action, makeObservable, observable } from "mobx";
import React from "react";
import ReactModal from "react-modal";
import { LocalDate } from "../../../adl-gen/common";
import { MaterialInstructions } from "../../../adl-gen/kachemedia/specsheet/db";
import styles from "./modal.css";
import { DatesModalContent } from "./templates/Dates";
import {
  MaterialInstructionsDisplayContent,
  MaterialInstructionsModalHeader,
} from "./templates/MaterialInstructions";
import {
  NotificationModalContent,
  NotificationModalHeader,
} from "./templates/Notification";
import {
  QuestionModalContent,
  QuestionModalHeader,
} from "./templates/Question";
import { InputValue, validInputValue } from "../inputs";
import { DownloadLinkSpinner } from "../DownloadLinkSpinner";
import { ModalHeaderProps, ModalInstance } from "./ModalCanvas";
import {
  PrimaryButton,
  PrimaryButtonProps,
  SecondaryButton,
  SecondaryButtonProps,
} from "../button/button";
import { CustomizedReactMarkdown } from "../../temporaryComponentsToShare/CustomizedReactMarkdown";
import { Table, TableCell, TableRow } from "../table/table";

/** Global modal store */
class Store {
  constructor() {
    makeObservable(this, {
      _instance: observable.ref,
      push: action,
      pop: action,
    });
  }

  private readonly modalStack: ModalInstance[] = [];

  /** Instance of single active modal */
  _instance?: ModalInstance = undefined;

  /** Return a readonly instance */
  get instance(): ModalInstance | undefined {
    return this._instance;
  }

  private setModal(instance: ModalInstance | undefined) {
    this._instance = instance;
  }

  /** Add a modal */
  push(instance: ModalInstance) {
    this.modalStack.push(instance);
    this.setModal(instance);
  }

  /** Close modal (return to previous modal if any) */
  pop() {
    this.modalStack.pop();
    const topModal: ModalInstance | undefined =
      this.modalStack[this.modalStack.length - 1];
    this.setModal(topModal);
  }
}

export type ModalSize = "xs" | "sm" | "md" | "lg";

// Set root element for accessibility
// See: http://reactcommunity.org/react-modal/accessibility/
if (process.env.NODE_ENV !== "test") {
  ReactModal.setAppElement("#root");
}

/* Instantiate global modal store */
export const store = new Store();

/** Shows a modal by setting it on the global store - Adds another modal to stack */
export function pushModal(instance: ModalInstance) {
  store.push(instance);
}

/**
 *
 * Shows an informational modal
 */
export function showInfoModal(
  content: JSX.Element,
  a11yLabel: string,
  header?: ModalHeaderProps,
  size?: ModalSize,
  customise?: Partial<ModalInstance>
) {
  return new Promise((resolve: (val?: string) => void) => {
    const onClickButton = () => {
      resolve(undefined);
      store.pop();
    };
    const onRequestClose = () => {
      resolve(undefined);
      store.pop();
    };
    pushModal({
      content,
      header,
      a11yLabel,
      buttons: [
        <PrimaryButton key="ok" onClick={onClickButton}>
          OK
        </PrimaryButton>,
      ],
      onRequestClose,
      shouldCloseOnOverlayClick: true,
      className: size ? styles[size + "Modal"] : undefined,
      ...customise,
    });
  });
}

/**
 * Shows a small notification modal
 */
export function showNotificationModal(title: string, content: string) {
  return showInfoModal(
    <NotificationModalContent content={content} />,
    content,
    {
      el: <NotificationModalHeader title={title} />,
    },
    "xs"
  );
}

/** Shows a simple question modal */
/** Shows a simple question modal */
/** Shows the edit dates modal for report download */
export function showEditReportDatesModal({
  organisationID,
  authToken,
}: {
  organisationID: string;
  authToken?: string;
}) {
  return new Promise<void>((resolve) => {
    //For Report Button
    //Get Dcurrent date and set default dates to last month
    const now = new Date();
    const currentDate: Date = new Date(
      now.getTime() + now.getTimezoneOffset() * 60_000
    );
    const startDate: string =
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      currentDate.getFullYear() +
      "-" +
      (currentDate.getMonth() === 0 ? 12 : `0${currentDate.getMonth()}`) +
      "-01";
    const endDate: string =
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      currentDate.getFullYear() +
      "-" +
      `0${currentDate.getMonth() + 2}` +
      "-01";
    const downloadAsFilename: string =
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      organisationID +
      "-" +
      currentDate.getFullYear() +
      "-" +
      (currentDate.getMonth() + 1) +
      "-" +
      currentDate.getDay() +
      ".csv";

    const headers: Headers = new Headers();
    headers.append("content-type", "application/json;charset=UTF-8");
    if (authToken !== undefined) {
      headers.append("X-Auth-Token", authToken);
    }
    const init = {
      method: "POST",
      headers,
      body: JSON.stringify({
        organisationId: organisationID,
        startDate,
        endDate,
      }),
    };

    const onRequestClose = () => {
      resolve();
      store.pop();
    };

    const valueStartObs = observable({
      value: validInputValue(startDate ?? ""),
    });
    const valueEndObs = observable({
      value: validInputValue(endDate ?? ""),
    });

    const checkDateValues = async (
      givenStartDate: string,
      givenEndDate: string
    ) => {
      const sDate: number = Date.parse(givenStartDate);
      const eDate: number = Date.parse(givenEndDate);
      if (sDate < eDate) {
        return true;
      }
      await showNotificationModal(
        "Date Error",
        "The selected Start date must be before the selected end date!"
      );
      return false;
    };
    const onStartChangeValue = async (value: InputValue<LocalDate>) => {
      if (await checkDateValues(value.value, valueEndObs.value.value)) {
        valueStartObs.value = value;
        init.body = JSON.stringify({
          organisationId: organisationID,
          startDate: value.value,
          endDate,
        });
      }
    };
    const onEndChangeValue = async (value: InputValue<LocalDate>) => {
      if (await checkDateValues(valueStartObs.value.value, value.value)) {
        valueEndObs.value = value;
        init.body = JSON.stringify({
          organisationId: organisationID,
          startDate,
          endDate: value.value,
        });
      }
    };

    const content = (
      <div className={styles.container}>
        <p className={styles.content}>
          Enter start and end date for the report.
        </p>
        <Table className={styles.content}>
          <TableRow>
            <TableCell>Start Date:</TableCell>
            <TableCell>End Date:</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <DatesModalContent
                date={valueStartObs}
                onChange={onStartChangeValue}
              />
            </TableCell>
            <TableCell>
              <DatesModalContent
                date={valueEndObs}
                onChange={onEndChangeValue}
              />
            </TableCell>
          </TableRow>
        </Table>
        <div className={styles.content}>
          <DownloadLinkSpinner
            fetch={{
              input: "/_a/campaign/report/",
              init,
            }}
            downloadAsFilename={downloadAsFilename}
          />
        </div>
      </div>
    );

    const header = {
      el: <QuestionModalHeader title={"Download Created Campaign Report"} />,
    };
    const a11yLabel = "Download Report";

    pushModal({
      content,
      header,
      a11yLabel,
      buttons: [],
      onRequestClose,
      shouldCloseOnOverlayClick: true,
      className: styles.modalXsSize,
    });
  });
}
