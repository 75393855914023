import { ErrorOutline as ErrorOutlineIcon } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import { LocalDate } from "src/adl-gen/common";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import styles from "./dates.css";
import {
  formatDate,
  fromJSDate,
  HUMAN_READABLE_DATE_FORMAT_EXPANDED,
  toJSDate,
} from "@specsheet-common/shared-tools";
import { InputValue, validInputValue } from "../../inputs";
import { BookingView } from "@specsheet-common/shared-types";

export interface DatesModalContentProps {
  // Callback for changes in the value
  onChange(value: InputValue<LocalDate>): void;
  // Value on the date
  date: { value: InputValue<LocalDate> };
}

export const DatesModalContent = observer(
  (props: DatesModalContentProps): JSX.Element => {
    /** Renders the modal content */
    return (
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Box sx={{ flex: 1 }}>
          <DateCalendar
            value={toJSDate(props.date.value.value)}
            onChange={(value) => {
              if (value) {
                props.onChange(validInputValue(fromJSDate(value)));
              }
            }}
          />
        </Box>
        <Typography variant={"h6"} color={"primary"}>
          {formatDate(
            props.date.value.value,
            HUMAN_READABLE_DATE_FORMAT_EXPANDED
          )}
        </Typography>
      </Box>
    );
  }
);
